<template>
  <div class="data_report">
    <div class="data_report-head">
      <div class="block">
        <el-date-picker
          v-model="value2"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '00:00:00']"
          value-format="yyyy-MM-dd"
          @change="clearDate"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div style="width:126px;margin-left:10px;">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-left:20px">
        <el-button type="primary" icon="el-icon-search" @click="search"
          >搜索</el-button
        >
      </div>
      <div class="data_report-head-btn">
        <el-button type="danger" @click="shows">新增收支</el-button>
      </div>
    </div>

    <div class="data_report-box">
      <div class="all">
        <div class="all-head">
          <div
            class="all-head-list"
            v-for="(item, index) in allData"
            :key="index"
          >
            <div class="all-head-list-l">
              <div class="all-head-list-img">
                <img :src="item.img" alt="" />
              </div>
            </div>
            <div class="all-head-list-r">
              <div class="all-head-list-title">
                {{ item.name }}
              </div>
              <div class="all-head-list-num">
                <span style="font-size:15px;">￥</span>
                <span :id="'all-head-list-num-' + index">0.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alls-head">
        <span>收支统计</span>
        <!-- <span
          class="el-icon-menu"
          @click="tabShow(false)"
          :class="tabStatus ? '' : 'alls-head-color'"
        ></span> -->
        <!-- <span
          class="el-icon-pie-chart"
          @click="tabShow(true)"
          :class="tabStatus ? 'alls-head-color' : ''"
        ></span> -->
      </div>
      <div class="alls" v-show="!tabStatus">
        <div class="alls-heads">
          <div class="alls-item">收支类型</div>
          <div class="alls-item">收支金额</div>
          <div class="alls-item">操作员工</div>
          <div class="alls-item">支付方式</div>
          <div class="alls-item">收支类目</div>
          <div class="alls-item">收支时间</div>
          <div class="alls-item">收支备注</div>
        </div>
        <div v-if="tab.length">
          <div class="alls-list" v-for="(item, index) in tab" :key="index">
            <div class="alls-item">{{ item.type - 0 ? "收入" : "支出" }}</div>
            <div class="alls-item">
              <span v-if="!item.type - 0" style="color: rgb(242, 95, 74);">
                -￥{{ item.amount }}
              </span>
              <span v-else> ￥{{ item.amount }} </span>
            </div>
            <div class="alls-item">
              {{ item.staff ? item.staff.name : "--" }}
            </div>
            <div class="alls-item">
              <span v-if="item.pay_type - 0 == 1">现金</span>
              <span v-if="item.pay_type - 0 == 2">微信</span>
              <span v-if="item.pay_type - 0 == 3">支付宝</span>
            </div>
            <div class="alls-item">{{ item.name || "--" }}</div>
            <div class="alls-item">{{ item.date }}</div>
            <div class="alls-item">
              <!-- {{ item.remark || "无备注" }} -->
              <el-popover
                placement="top-start"
                title="备注"
                width="200"
                trigger="click"
                :content="item.remark || '无备注...'"
              >
                <el-button slot="reference" icon="el-icon-chat-dot-round"
                  >查看备注</el-button
                >
              </el-popover>
            </div>
          </div>
        </div>
        <div class="alls-list" v-else>
          <div class="alls-item" style="color:#ccc">暂无数据</div>
        </div>
        <el-pagination
          v-if="$pageStatus(total)[0]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="$pageStatus(total)[1]"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="other" v-show="tabStatus">
        <div class="other-box">
          <div class="other-box-l">
            <div id="alls"></div>
          </div>
          <div class="other-box-r">
            <div id="alls1"></div>
          </div>
        </div>
      </div>

      <div class="other" v-if="0">
        <div class="other-head">
          <div class="other-head-name">交易看板</div>
          <div class="other-head-tab">
            <el-radio-group v-model="tabPosition">
              <el-radio-button label="top">实收构成</el-radio-button>
              <el-radio-button label="right">实收方式</el-radio-button>
              <el-radio-button label="bottom">消耗构成</el-radio-button>
              <el-radio-button label="left">消耗方式</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="other-box">
          <div class="other-box-l">
            <el-alert
              title="实际构成按金额排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
          <div class="other-box-r">
            <el-alert
              title="实际构成按金额占比"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <div id="jioayi" class="tubiao"></div>
          </div>
        </div>
      </div>

      <div class="other" v-if="0">
        <div class="other-head">
          <div class="other-head-name">交易看板</div>
          <div class="other-head-tab">
            <!-- <el-radio-group v-model="tabPosition">
              <el-radio-button label="top">实收构成</el-radio-button>
              <el-radio-button label="right">实收方式</el-radio-button>
              <el-radio-button label="bottom">消耗构成</el-radio-button>
              <el-radio-button label="left">消耗方式</el-radio-button>
            </el-radio-group> -->
          </div>
        </div>
        <div class="other-box">
          <div class="other-box-l">
            <!-- <el-alert
              title="实际构成按金额排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert> -->
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
          <div class="other-box-r">
            <!-- <el-alert
              title="实际构成按金额占比"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert> -->
            <div id="jioayi" class="tubiao"></div>
          </div>
        </div>
      </div>

      <div class="other" v-if="0">
        <div class="other-head">
          <div class="other-head-name">销售排行</div>
          <div class="other-head-tab">
            <el-radio-group v-model="tabPosition">
              <el-radio-button label="top">卡项排行</el-radio-button>
              <el-radio-button label="right">项目排行</el-radio-button>
              <el-radio-button label="bottom">产品排行</el-radio-button>
              <el-radio-button label="left">套餐排行</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="other-box">
          <div class="other-box-l" style="width:49.5%;margin-right:1%">
            <el-alert
              title="卡项按销量排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
          <div class="other-box-r" style="width:49.5%">
            <el-alert
              title="卡项按销量金额排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加收支 -->
    <el-dialog title="添加收支" :visible.sync="dialogVisible" width="800px">
      <div class="edit">
        <div class="edit-list">
          <div class="edit-list-item">
            <div class="edit-list-item-lable">收支类目：</div>
            <div class="edit-list-item-input">
              <el-input
                v-model="forms.name"
                style="width:100%"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="edit-list-item">
            <div class="edit-list-item-lable">收支类型：</div>
            <div class="edit-list-item-input">
              <el-select
                v-model="forms.type"
                placeholder="请选择收支类型"
                style="width:100%"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="edit-list-item">
            <div class="edit-list-item-lable">收支金额：</div>
            <div class="edit-list-item-input">
              <el-input-number
                style="width:100%"
                v-model="forms.amount"
                :precision="2"
                :step="0.1"
                :max="999999999"
                :min="0.1"
              ></el-input-number>
            </div>
          </div>
          <div class="edit-list-item">
            <div class="edit-list-item-lable">支付方式：</div>
            <div class="edit-list-item-input">
              <el-select
                v-model="forms.pay_type"
                placeholder="请选择支付方式"
                style="width:100%"
              >
                <el-option
                  v-for="item in pays"
                  :key="item.pay_type"
                  :label="item.name"
                  :value="item.pay_type"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="edit-list">
          <div class="edit-list-item">
            <div class="edit-list-item-lable">操作员工：</div>
            <div class="edit-list-item-input">
              <el-select
                v-model="forms.staff_id"
                placeholder="请选择操作员工"
                filterable
                style="width:100%"
              >
                <el-option
                  v-for="item in work"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="edit-list-item">
            <div class="edit-list-item-lable">开支日期：</div>
            <div class="edit-list-item-input">
              <el-date-picker
                style="width:100%"
                v-model="forms.date"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="edit-list-item">
            <div class="edit-list-item-lable">备注信息：</div>
            <div class="edit-list-item-input">
              <el-input
                type="textarea"
                placeholder="请输入备注信息"
                v-model="forms.remark"
                maxlength="150"
                show-word-limit
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="adds">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加收支 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: [],
      allData: [
        { name: "收入", num: "0.00", img: require("../../assets/none.png") },
        { name: "支出", num: "0.00", img: require("../../assets/none.png") },
        // { name: "商品", num: "0.00", img: require("../../assets/none.png") },
        // { name: "售卡", num: "0.00", img: require("../../assets/none.png") },
        // { name: "充值", num: "0.00", img: require("../../assets/none.png") },
        // { name: "套餐卡", num: 99999, num1: 324, num2: 564654 },
      ],
      tabStatus: false, //切换表格图标显示
      tab: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "支出",
        },
        {
          value: "3",
          label: "收入",
        },
      ],
      value: "1",
      total: 1,
      page: 1,
      work: [], //员工数据
      pays: [
        {
          pay_type: 1,
          name: "现金",
        },
        {
          pay_type: 2,
          name: "微信",
        },
        {
          pay_type: 3,
          name: "支付宝",
        },
      ], //支付方式
      dialogVisible: false,
      forms: {
        type: "1",
        name: "",
        amount: 1,
        pay_type: 1,
        staff_id: "",
        remark: "",
        date: "",
      },
      options1: [
        {
          value: "0",
          label: "支出",
        },
        {
          value: "1",
          label: "收入",
        },
      ],
    };
  },
  methods: {
    /* 数字动画 */
    nums(obj, dom) {
      let num = obj - 0; //总数
      let step = num / (1000 / 50); //每30ms增加的数值
      let count = 0; //计数器
      let timer = null; //计时器
      let initial = 0;
      let text = document.querySelector(dom); //dom
      if (!text) {
        return "0.00";
      }
      clearInterval(timer);
      timer = setInterval(() => {
        count += step;
        if (count >= num) {
          clearInterval(timer);
          count = num;
        }
        //t未发生改变的话就直接返回
        let t = count;
        if (t == initial) return;
        initial = t;
        text.innerHTML = initial.toFixed(2);
      }, 50);
    },
    /* 搜索 */
    search() {
      // console.log(this.value2);
      // if (!this.value2) {
      //   this.value2 = [];
      // }
      // if (!this.value2.length && this.value > 1) {
      //   this.$message({
      //     message: "请选择日期",
      //     type: "warning",
      //   });
      //   return false;
      // }
      this.page = 1;
      this.getList();
    },
    /* 日期清除 */
    clearDate(val) {
      if (!val) {
        this.value2 = [];
        this.page = 1;
        this.getList();
      }
    },
    /* 获取数据 */
    getList() {
      let date1 = null;
      let date2 = null;
      let type = null;
      // let _this = this;
      if (this.value2.length) {
        date1 = this.value2[0];
        date2 = this.value2[1];
      }

      if (this.value > 1) {
        type = this.value - 2;
      } else {
        type = null;
      }
      this.$axios({
        href: "/api/app/meiye/shop_finance/store",
        data: { date1: date1, date2: date2, type: type, page: this.page },
      }).then((res) => {
        console.log(res.data.data);
        this.$nums(res.data.data.total_in_amount,"#all-head-list-num-0");
        this.$nums(res.data.data.total_out_amount,"#all-head-list-num-1")
        this.tab = res.data.data.items;
        this.total = res.data.data.total;
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.page = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
      // console.log(`当前页: ${val}`);
    },
    /* 切换显示类型 obj*/
    tabShow() {
      this.tabStatus = false;
      // window.onresize = function() {
      //   myChart.resize();
      // };
    },
    /* 获取员工 */
    getWork() {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        // console.log(res);
        this.work = res.data;
      });
    },
    /* 获取支付方式 */
    getPay(obj) {
      let len = this.pays.length;
      for (let i = 0; i < len; i++) {
        if (obj - 0 == this.pays[i].pay_type - 0) {
          return this.pays[i].name;
        }
      }
      return "其他";
    },
    /* 显示收支添加窗口 */
    shows() {
      this.forms = {
        type: "1",
        name: "",
        amount: 1,
        pay_type: 1,
        staff_id: "",
        remark: "",
        date: "",
      };
      this.dialogVisible = true;
    },
    /* 添加收支 */
    adds() {
      let d = this.forms;
      if (!d.name) {
        this.$message({
          message: "请输入类目",
          type: "warning",
        });
        return false;
      }
      if (d.type - 0 != 0 && d.type - 0 != 1) {
        this.$message({
          message: "请选择支出类型",
          type: "warning",
        });
        return false;
      }
      // if (!d.staff_id) {
      //   this.$message({
      //     message: "请选择操作员工",
      //     type: "warning",
      //   });
      //   return false;
      // }
      if (!d.amount) {
        this.$message({
          message: "请输入支出金额",
          type: "warning",
        });
        return false;
      }
      if (!d.pay_type) {
        this.$message({
          message: "请选择支付方式",
          type: "warning",
        });
        return false;
      }

      this.$axios({
        href: "/api/app/meiye/shop_finance/add",
        data: d,
      }).then(() => {
        this.$message({
          message: "恭喜你，添加收支成功~",
          type: "success",
        });
        this.dialogVisible = false;
        this.getList();
      });
    },
    inits(obj) {
      return this.$echarts.init(document.getElementById(obj), "walden");
    },
    alls() {
      let myChart = this.inits("alls");
      let d = this.allData;
      let getname = [
        // "现金",
        // "银联",
        // "微信",
        // "支付宝",
        // "卡金",
        // "疗程耗卡",
      ];
      // var getvalue = [35, 45, 30];
      // var getbl = [35, 45, 30];

      let data = [];
      for (let i = 0; i < d.length; i++) {
        data.push({
          name: d[i].name,
          value: d[i].num,
        });
        getname.push(d[i].name);
      }

      let option = {
        tooltip: {
          trigger: "item",
        },

        legend: {
          type: "scroll",
          orient: "vertical",
          height: "88%",
          right: "10%",
          top: "center",
          itemWidth: 18,
          itemHeight: 18,
          data: getname,
          textStyle: {
            rich: {
              name: {
                fontSize: 15,
                fontWeight: 400,
                //width: 170,
                width: 230,
                height: 35,
                padding: [0, 0, 0, 5],
                color: "#999",
              },
              rate: {
                fontSize: 15,
                fontWeight: 500,
                height: 35,
                width: 40,
                align: "right",
                color: "#656565",
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "60%"],
            center: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: data,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function() {
        myChart.resize();
      };
    },
    jioayis() {
      let myChart = this.inits("alls1");
      let d = this.allData;
      let getname = ["现金", "银联", "微信", "支付宝", "卡金", "疗程耗卡"];

      let data = [];
      for (let i = 0; i < d.length; i++) {
        data.push({
          name: d[i].name,
          value: d[i].num,
        });
        // getname.push(d[i].pay);
      }

      let option = {
        tooltip: {
          trigger: "axis",
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: getname,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "邮件营销",
            type: "line",
            stack: "总量",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "联盟广告",
            type: "line",
            stack: "总量",
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: "视频广告",
            type: "line",
            stack: "总量",
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: "直接访问",
            type: "line",
            stack: "总量",
            data: [320, 332, 301, 334, 390, 330, 320],
          },
          {
            name: "搜索引擎",
            type: "line",
            stack: "总量",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function() {
        myChart.resize();
      };
      // window.onresize = function() {
      //   myChart.resize();
      //   };
    },
  },
  mounted() {
    let _this = this;
    this.alls();
    this.jioayis();
    this.getList();
    this.getWork();
    window.onresize = function() {
      _this.alls();
      _this.jioayis();
    };
  },
};
</script>

<style lang="scss" scoped>
.data_report {
  width: 100%;
  //   height: 100%;
  min-height: 100%;
  background: #fff;
  &-head {
    width: 100%;
    height: 80px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #eee;
    margin-bottom: 35px;
    position: relative;
    &-btn {
      position: absolute;
      top: 0;
      right: 15px;
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    // background: red;
  }
  &-box {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.all {
  width: 100%;
  height: 100%;
  &-head {
    width: 100%;
    height: 150px;
    display: flex;
    overflow-x: auto;

    // background: red;
    &-list {
      // float: left;
      width: 439px;
      border-radius: 6.5px 6.5px 0px 0px;
      min-width: 220px;
      height: 120px;
      padding: 0 10px;
      margin: 10px 1%;
      box-sizing: border-box;
      //   border-radius: 5px;
      cursor: pointer;
      background: #fff;
      background: #fff;
      border: 1px solid #eee;
      transition: all 0.5s;
      font-size: 13px;
      position: relative;
      display: flex;
      &-l {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-r {
        width: 70%;
        height: 100%;
        margin-left: 10px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      &-title {
        width: 100%;
        height: 40%;
        font-size: 16px;
        color: #8088a8;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      }
      &-num {
        width: 100%;
        height: 60%;
        font-size: 36px;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      // &-num:last-child {
      //   color:rgb(242, 95, 74);
      // }
      &-img {
        // position: absolute;
        // top: 10px;
        // right: 10px;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        // background: red;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    &-list:last-child &-list-num {
      color: rgb(242, 95, 74);
    }
    &-list:hover {
      //   margin-top: -1px;
      border-radius: 5px;
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #b3d8ff;
    }
  }
}
#alls,
#alls1 {
  margin: 20px 0;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  // background: red;
}
.alls {
  // margin: 20px 0;
  width: 100%;
  height: 560px;
  &-head {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      padding: 0 5px;
      cursor: pointer;
    }
    span:first-child {
      color: #8088a8;
    }
    &-color {
      color: #8088a8;
    }
  }
  &-heads,
  &-list {
    width: 100%;
    height: 60px;
    display: flex;
  }
  &-heads {
    background: #8088a8;
    color: #fff;
  }
  &-list {
    border-bottom: 1px solid #eee;
    transition: all 0.5s;
    // cursor: pointer;
  }
  &-list:nth-child(even) {
    background: #fafafa;
  }

  &-list:hover {
    background: #fafafa;
  }

  &-item {
    width: 100%;
    height: 100%;
    border-right: 1px solid #f2f2f2;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-list &-item:first-child {
    font-weight: 800;
  }
  &-item:last-child {
    border: none;
  }
  &-heads &-item {
    border: none;
  }
  &-item-super {
    width: 100%;
    height: 100%;
    // padding: 10px;
    // box-sizing: border-box;
    font-size: 13px;
    position: relative;
    display: block;
    &-type {
      width: 100%;
      height: 50%;
      padding: 10px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    &-line {
      position: absolute;
      top: 47%;
      left: 39%;
      width: 30%;
      height: 1px;
      background: #eee;
      transform: rotate(45deg);
    }
    &-type:first-child {
      justify-content: flex-end;
    }
    &-type:last-child {
      justify-content: flex-start;
    }
  }
}
.other {
  width: 100%;
  height: 100%;
  &-head {
    width: 100%;
    height: 60px;
    // background: red;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-name {
      width: 50%;
      height: 100%;
      padding: 0 10px;
      font-size: 20px;
      font-weight: 800;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-tab {
      width: 50%;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &-box {
    width: 100%;
    height: 500px;
    display: flex;
    &-l {
      width: 50%;
      height: 100%;
      margin-right: 10px;
    }
    &-r {
      width: 50%;
      height: 100%;
    }
  }
}
.tubiao {
  width: 100%;
  height: 100%;
}
.edit {
  width: 100%;
  height: 100%;
  display: flex;
  &-list {
    width: 100%;
    height: 100%;
    &-item {
      width: 100%;
      height: 50px;
      display: flex;
      // background: red;
      &-lable {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-input {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
